$cyan: #27aae1 !default;
$grey-darker: #172d36 !default;
$primary: $cyan !default;
$navbar-height: 5.625rem !default;
$navbar-item-img-max-height: 2.875rem !default;
$navbar-item-hover-background-color: transparent !default;
$navbar-link-hover-background-color: transparent !default;

@import '~bulma/bulma.sass';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: $cyan;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

dt {
  font-weight: bold;
}

.box {
  border: 1px solid #ccc;
}

.button.is-static.help-icon {
  pointer-events: inherit;
}

.tooltip {
  cursor: pointer;
}

.hover:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.5); 
  cursor: pointer;
}

.card {
  border: 1px solid #ccc;
}

.card.selected {
  box-shadow: 0 0 11px rgba(33,33,33,1);
  border: none;
}

.card.selected > .card-header {
  background-color: $cyan;
}

.card.selected > .card-header > .card-header-title > h2 {
  color: #fff;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.pre {
  white-space: pre-line;
}

h1.title {
  color: $cyan;
}

.navbar-item {
  padding: 0;
}

.navbar-item > img {
  padding-left: 3vw;
  padding-right: 3vw;
}

.strikethrough {
  text-decoration: line-through;
}

.bold {
  font-weight: bold;
}

.datepicker {
  width: auto;
}